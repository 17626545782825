// In WeatherInfo.js
import React from 'react';

const WeatherInfo = ({ weatherData }) => {
    if (!weatherData) return <div>No weather data available for this date.</div>;

    return (
        <div className="weather-info">
            <div className="weather-row">
                <div className="weather-item">
                    <span className="weather-label">Temperature:</span>
                    <span className="weather-value">{weatherData.temperature}°F</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Weather:</span>
                    <span className="weather-value">{weatherData.description}</span>
                </div>
            </div>
            <div className="weather-row">
                <div className="weather-item">
                    <span className="weather-label">Sunrise:</span>
                    <span className="weather-value">{weatherData.sunrise}</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Sunset:</span>
                    <span className="weather-value">{weatherData.sunset}</span>
                </div>
                <div className="weather-item">
                    <span className="weather-label">Daylight:</span>
                    <span className="weather-value">{weatherData.hoursOfDaylight}</span>
                </div>
            </div>
        </div>
    );
};

export default WeatherInfo;