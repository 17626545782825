import React from 'react';
import { Modal } from 'antd';

const WeeklySummaryModal = ({ isVisible, onClose, summaryData, dateRange }) => {
  console.log("WeeklySummaryModal received data:", summaryData);
  if (!summaryData) {
    return null;
  }

  return (
    <Modal
      title={
        <div>
          <h2 style={{ margin: 0, fontWeight: 'bold' }}>Weekly Summary</h2>
          <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 0 15px 0' }}>
            for {dateRange}
          </p>
        </div>
      }
      visible={isVisible}
      onOk={onClose}
      onCancel={onClose}
      footer={[
        <button key="ok" onClick={onClose} className="modal-button">
          OK
        </button>
      ]}
    >
      <div className="weekly-summary">
        <div className="summary-section">
          <h3 style={{ fontSize: '18px', color: '#1a9cd4' }}>Sleep</h3>
          <p><strong>Total Sleep Hours:</strong> {summaryData.sleep?.totalHours || 'N/A'}</p>
          <p><strong>Average Sleep Hours per Night:</strong> {summaryData.sleep?.averageHours || 'N/A'}</p>
          <p><strong>Average Arise Time:</strong> {summaryData.sleep?.averageAriseTime || 'N/A'}</p>
          <p><strong>Average Bedtime:</strong> {summaryData.sleep?.averageBedtime || 'N/A'}</p>
          {summaryData.sleep?.totalHours.includes('*') && 
            <p><small>* Some days had incomplete sleep data and were not included in the calculation.</small></p>
          }
        </div>
        
        <div className="summary-section">
          <h3 style={{ fontSize: '18px', color: '#1a9cd4' }}>Beverages & Food</h3>
          <p><strong>Total Number of Diet Cokes:</strong> {summaryData.beverages?.totalDietCokes || 'N/A'}</p>
          <p><strong>Average Diet Cokes Per Day:</strong> {summaryData.beverages?.avgDietCokesPerDay || 'N/A'}</p>
          <p><strong>Total Number of Milks:</strong> {summaryData.beverages?.totalMilks || 'N/A'}</p>
          <p><strong>Average Milks Per Day:</strong> {summaryData.beverages?.avgMilksPerDay || 'N/A'}</p>
          <p><strong>Cheat Day?</strong> {summaryData.beverages?.hasCheatDay ? 'Yes' : 'No'}</p>
       </div>

        <div className="summary-section">
          <h3 style={{ fontSize: '18px', color: '#1a9cd4' }}>Exercise</h3>
          <p><strong>Total Walking Miles:</strong> {summaryData.exercise?.totalWalkingMiles || 'N/A'}</p>
          <p><strong>Total Rucking Days:</strong> {summaryData.exercise?.totalRuckingDays || 'N/A'}</p>
          <p><strong>Total Resistance Days:</strong> {summaryData.exercise?.totalResistanceDays || 'N/A'}</p>
        </div>
        <div className="summary-section">
          <h3 style={{ fontSize: '18px', color: '#1a9cd4' }}>Mood</h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ marginRight: '20px' }}>
              <strong>Flags:</strong> {summaryData.mood?.totalFlags ?? 'N/A'}
            </p>
            <p>
              <strong>Red Zone:</strong> {summaryData.mood?.totalRedZone ?? 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WeeklySummaryModal;