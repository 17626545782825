import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function ViewData() {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('https://omniscient21.com:5003/view-all-data')
            .then(response => {
                console.log('Fetched data:', response.data);
                const sortedData = sortData(response.data);
                console.log('Sorted data:', sortedData);
                setData(sortedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const sortData = (data) => {
        return data.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            if (dateA < dateB) return -1;
            if (dateA > dateB) return 1;

            if (a.section < b.section) return -1;
            if (a.section > b.section) return 1;

            if (a.field < b.field) return -1;
            if (a.field > b.field) return 1;

            return 0;
        });
    };

    return (
        <div className="container">
            <h2>View Data</h2>
            {data.length === 0 ? (
                <p>No data available.</p>
            ) : (
                <table className="data-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Section</th>
                            <th>Field</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{new Date(item.date).toLocaleDateString()}</td>
                                <td>{item.section}</td>
                                <td>{item.field}</td>
                                <td>{item.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <footer className="data-footer">
                <button className="home-button" onClick={() => navigate('/')}>Home</button>
            </footer>
        </div>
    );
}

export default ViewData;