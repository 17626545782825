import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { DeleteOutlined, EditOutlined, SaveOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ViewNotesModal = ({ isVisible, onClose, notes }) => {
    const [sortedNotes, setSortedNotes] = useState([]);
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [editedContent, setEditedContent] = useState('');
    const [saveInProgress, setSaveInProgress] = useState(false);

    useEffect(() => {
        if (Array.isArray(notes)) {
            const sorted = [...notes].sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            });
            setSortedNotes(sorted);
        } else {
            setSortedNotes([]);
        }
    }, [notes]);

    const formatDate = (dateString) => {
        return dayjs(dateString).format('dddd, MMMM D, YYYY');
    };

    const handleDelete = async (noteId, date) => {
        try {
            setDeleteInProgress(true);
            await axios.delete(`https://omniscient21.com:5003/delete-general-note/${noteId}`);
            setSortedNotes(sortedNotes.filter(note => note.id !== noteId));
        } catch (error) {
            console.error('Error deleting note:', error);
        } finally {
            setDeleteInProgress(false);
        }
    };

    const startEditing = (note) => {
        setEditingNoteId(note.id);
        setEditedContent(note.notes);
    };

    const cancelEditing = () => {
        setEditingNoteId(null);
        setEditedContent('');
    };

    const handleSave = async (noteId, date) => {
        try {
            setSaveInProgress(true);
            await axios.put(`https://omniscient21.com:5003/update-general-note/${noteId}`, {
                notes: editedContent,
                date: date
            });
            
            // Update the local state with the edited content
            setSortedNotes(sortedNotes.map(note => 
                note.id === noteId 
                    ? { ...note, notes: editedContent }
                    : note
            ));
            
            setEditingNoteId(null);
            setEditedContent('');
        } catch (error) {
            console.error('Error updating note:', error);
        } finally {
            setSaveInProgress(false);
        }
    };

    const modules = {
        toolbar: [
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            [{ 'color': [] }, { 'background': [] }],
            ['link']
        ]
    };

    return (
        <Modal
            title={
                <div style={{ textAlign: 'left', fontSize: '18px', marginBottom: '15px' }}>
                    General Notes
                </div>
            }
            visible={isVisible}
            onCancel={onClose}
            onOk={onClose}
            okText="OK"
            cancelButtonProps={{ style: { display: 'none' } }}
            width={800}
            bodyStyle={{ 
                padding: '20px',
                maxHeight: '600px',
                overflowY: 'auto'
            }}
        >
            <div className="view-notes-content">
                {sortedNotes.length > 0 ? (
                    sortedNotes.map((note) => (
                        <div key={note.id} className="note-entry">
                            <div className="note-header">
                                <div className="note-date">
                                    {formatDate(note.date)}
                                </div>
                                <div className="note-actions">
                                    {editingNoteId === note.id ? (
                                        <>
                                            <SaveOutlined
                                                className="save-note-icon"
                                                onClick={() => handleSave(note.id, note.date)}
                                                disabled={saveInProgress}
                                            />
                                            <CloseOutlined
                                                className="cancel-edit-icon"
                                                onClick={cancelEditing}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <EditOutlined
                                                className="edit-note-icon"
                                                onClick={() => startEditing(note)}
                                            />
                                            <DeleteOutlined
                                                className="delete-note-icon"
                                                onClick={() => handleDelete(note.id, note.date)}
                                                disabled={deleteInProgress}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                            {editingNoteId === note.id ? (
                                <div className="note-editor">
                                    <ReactQuill
                                        value={editedContent}
                                        onChange={setEditedContent}
                                        modules={modules}
                                    />
                                </div>
                            ) : (
                                <div 
                                    className="note-text"
                                    dangerouslySetInnerHTML={{ __html: note.notes }}
                                />
                            )}
                        </div>
                    ))
                ) : (
                    <div className="no-notes-message">
                        No notes have been entered.
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ViewNotesModal;